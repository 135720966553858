<template>
  <div style="background-color: #0070d1">
    <div style="background-color: #ffb71b; width: 100%; height: 1rem"></div>
    <v-container>
      <v-layout row wrap>
        <div class="footer-element">
          <v-col class="ma-3 pa-0">          
              <br>
              Este riesgo es tomado por         
              <v-img
            class="shrink mt-1 hidden-sm-and-down"
            contain
            max-height="100"
            :src="LogoSB"
            max-width="175"
          />

           © {{year}} Powered By &thinsp;<a style="color:white" href="https://embedx.io/"> EmbedX</a>

          </v-col>
          
        </div>
      </v-layout>
    </v-container>
  </div>
  
</template>

<script>
import LogoLiderBCI from "@/assets/images/LogoLiderBCI.png";
import LogoSB from "@/assets/images/SB_logo.png";

export default {
  name: "Footer",
    mounted(){
      var currentTime = new Date();
      var year = currentTime.getFullYear()
      this.year = year;
      console.log(this.year)
    },
  data: () => ({
    year: null,
    LogoLiderBCI,
    LogoSB
  }),
};
</script>

<style lang="scss" scoped>
@import url("../styles/main.scss");

.footer-element {
  color: #FFFF;
  min-height: 200px;
  display: flex;
  align-items: flex-start;

  li {
    margin-bottom: 1rem;
  }

  p {
    line-height: 1;
    margin-bottom: 0.5rem;
  }

  .ln {
    color : #ffb71b
  }
}

.layout.wrap {
  justify-content: space-around;
}

.footer{
    section{
        display: flex;
        justify-content: center;
        padding: 0rem 0;
        div {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin: 0.7rem;
            div{
                padding: 0.5rem 0;
                display: flex;
                flex-direction: column;
                a{
                    text-decoration: none;
                    padding-bottom: 1rem;
                }
            }
        }
        .footer-contact{
            img{
                width: 60%;
            }
            div{
                display: flex;
                flex-direction: row;
                a{
                    padding-bottom: 0;
                }
                img{
                    width: 8%;
                    padding-right: 10px;
                }
            }
            .footer-contact-rrss{
                img{
                    width: 20%
                }
            }
        }
        &:last-child{
            background: #0070d1;
            padding: 10px;
            p{
                display: flex;
                margin: auto;
                color: #FFF;
                img{
                    margin: 3px;
                }
            }
        }
    }
}

</style>
